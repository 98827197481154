const unitsArray = [
    { value: '片', label: '片' },
    { value: '粒', label: '粒' },
    { value: '支', label: '支' },
    { value: '丸', label: '丸' },
    { value: '瓶', label: '瓶' },
    { value: '板', label: '板' },
    { value: '条', label: '条' },
    { value: '袋', label: '袋' },
    { value: '盒', label: '盒' },
    { value: '箱', label: '箱' },
    { value: '卷', label: '卷' },
    { value: '贴', label: '贴' },
    { value: '包', label: '包' },
    { value: '克', label: '克' },
    { value: '穴', label: '穴' },
    { value: '毫克', label: '毫克' },
    { value: '毫升', label: '毫升' },
    { value: '半支', label: '半支' },
    { value: '半袋', label: '半袋' },
    { value: '件', label: '件' },
    { value: 'g', label: 'g' },
    { value: 'kg', label: 'kg' },
    { value: 'ml', label: 'ml' },
    { value: 'mg', label: 'mg' },
    { value: 'L', label: 'L' },
    { value: '杯', label: '杯' },
    { value: '桶', label: '桶' },
    { value: '盆', label: '盆' },
    { value: '统', label: '统' },
    { value: '套', label: '套' },
    { value: '只', label: '只' },
    { value: '罐', label: '罐' },
    { value: '次', label: '次' },
    { value: '张', label: '张' },
    { value: '副', label: '副' },
    { value: '辆', label: '辆' },
    { value: '碗', label: '碗' },
    { value: '对', label: '对' },
];

export default unitsArray;
  